import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from "ajv";
const ajv = new Ajv({ allErrors: true, verbose: true });
const validate = ajv.getSchema("https://w3id.org/openbadges/v2");
const IssuerData = {
  "@context": "https://w3id.org/openbadges/v2",
  "type": "Issuer",
  "id": "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json",
  "url": "https://imsjapan.org",
  "email": "contactopenbadges@imsjapan.org",
  "name": "IMS Japan Badge Issuer Demo"
};

const BadgeClassData = {
  "@context": "https://w3id.org/openbadges/v2",
  "type": "BadgeClass",
  "id": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
  "name": "IMS Japan Conference 2019 Demo Badge",
  "description": "IMS Japan Conference 2019 Demo.",
  "image": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoImage.svg",
  "criteria": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoCriteria.html",
  "issuer": "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json"
};

const AssertionDataAll = {
  "@context": "https://w3id.org/openbadges/v2",
  "type": "Assertion",
  "id": "https://demo.mananda.net/openbadges/Assertion/imsjapandemoAssertion.json",
  "recipient": {
    "type": "email",
    "hashed": true,
    "salt": "imsjapan",
    "identity": "sha256$3e6a147d32ed200d557e53d15702145581962acc3d22e3777f7705066c7e2144"
  },
  "issuedOn": "2019-09-20T09:00:00Z",
  "Badge": {
    "type": "BadgeClass",
    "id": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
    "name": "IMS Japan Conference 2019 Demo Badge",
    "description": "IMS Japan Conference 2019 Demo.",
    "image": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoImage.svg",
    "criteria": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoCriteria.html",
    "issuer": {
      "@context": "https://w3id.org/openbadges/v2",
      "type": "Issuer",
      "id": "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json",
      "url": "https://imsjapan.org",
      "email": "contactopenbadges@imsjapan.org",
      "name": "IMS Japan Badge Issuer Demo"
    }
  },
  "verification": {
    "type": "hosetd"
  }
};

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});


class InfoAssertionAll extends React.Component {

  render() {

    // Material-ui関連
    const { classes } = this.props;

    return (
      <div>
        <h2>Assertion統合</h2>
        <div className={classes.textLeft}>
        
          <Paper className={classes.root} elevation={1}>


            <div>


<Editor
  value={AssertionDataAll}
  // // onChange={this.handleChange}
  ajv={ajv}
  schema={validate}
/>

</div>
          </Paper>
          

        
        </div>
      </div>
    );
  }
}

// Material-ui関連
InfoAssertionAll.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(InfoAssertionAll);