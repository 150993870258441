import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from "ajv";
const ajv = new Ajv({ allErrors: true, verbose: true });
const validate = ajv.getSchema("https://w3id.org/openbadges/v2");
const IssuerData = {
  "@context": "https://w3id.org/openbadges/v2",
  "type": "Issuer",
  "id": "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json",
  "url": "https://imsjapan.org",
  "email": "contactopenbadges@imsjapan.org",
  "name": "IMS Japan Badge Issuer Demo"
};

const BadgeClassData = {
  "@context": "https://w3id.org/openbadges/v2",
  "type": "BadgeClass",
  "id": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
  "name": "IMS Japan Conference 2019 Demo Badge",
  "description": "IMS Japan Conference 2019 Demo.",
  "image": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoImage.svg",
  "criteria": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoCriteria.html",
  "issuer": "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json"
};

const AssertionData = {
  "@context": "https://w3id.org/openbadges/v2",
  "type": "Assertion",
  "id": "https://demo.mananda.net/openbadges/Assertion/imsjapandemoAssertion.json",
  "recipient": {
    "type": "email",
    "hashed": true,
    "salt": "imsjapan",
    "identity":
    "sha256$3e6a147d32ed200d557e53d15702145581962acc3d22e3777f7705066c7e2144"
},
  "badge": "https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoBadgeClass.json",
  "verification": {
    "type": "hosetd"
  },
  "issuedOn": "2019-09-20T09:00:00Z"
};

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});


class Info extends React.Component {

  render() {

    // Material-ui関連
    const { classes } = this.props;

    return (
      <div>
        <h2>Open Badges v2.0 について</h2>
        <h2>デモサイト : https://demo.mananda.net : デモの目的で使用することを承諾します。</h2>
        
        <div className={classes.textLeft}>
        

          {/* <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              構成要素
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              本アプリでは下記技術を用いており、各サービスの仕様変更ならびに障害発生時には、本アプリの提供・公開を中断する場合もございます。予めご了承下さい。
            </Typography>
            <Typography component="div" className={classes.paragraph}>
              <ul>
                <li>Firebase Hosting</li>
                <li>ShangriLa Anime API V1</li>
                <li>React・Redux・Material-UI</li>
              </ul>
            </Typography>
          </Paper> */}
          
          {/* <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              本アプリの目的
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              このアプリは、SPAアプリ作成入門者が、React・Redux・Material-UI等を使ってアプリを作るためのHowTo用途で作成しています。
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              コードも公開しているのでこんな感じのアプリを作りたい方は下記記事などを参考にしてみて下さい。ただ、私自身も初学者のためあくまで参考程度にご利用下さい。（ご指摘・質問など大歓迎）
            </Typography>
          </Paper> */}
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              IMS GLOBAL 技術標準仕様
            </Typography>
            <Typography component="p" className={classes.paragraph}>
            Open Badges v2.0 ：
              <a href="https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/index.html" target="_blank" rel="noopener noreferrer">
              IMS Final Release
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
            Open Badges Examples：
              <a href="https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/examples/index.html" target="_blank" rel="noopener noreferrer">
              IMS Final Release
              </a>
            </Typography>
          </Paper>
          
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              ツール紹介
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://badgecheck.io/" target="_blank" rel="noopener noreferrer">
                検証ツール: Open Badges 2.0 Validator :Badgr
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://openbadgesvalidator.imsglobal.org" target="_blank" rel="noopener noreferrer">
                検証ツール: IMS Global Open Badges 2.0 Validator
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://editor.method.ac/" target="_blank" rel="noopener noreferrer">
              SVG Editor: method.
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://www.milk-island.net/javascript/hashgenerator/sha2_256.html" target="_blank" rel="noopener noreferrer">
              SHA256ハッシュ生成ツール: method.
              </a>
            </Typography>
            {/* <Typography component="p" className={classes.paragraph}>
              フリーランスで働き方改革＆模索中。モノを作る事が好き。プログラミングの講師業もやってます。 
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              PHP、Ruby on Railsあたりを好んでおりますが基本雑食。フロントエンド～サーバーサイドまでやれる、なんちゃってフルスタックエンジニアを目指しています。
              <a href="https://blog.f-arts.work/" target="_blank" rel="noopener noreferrer">
                ブログ
              </a> 
              でWordPressやRoR系のネタで記事投稿してますので是非遊びにいらして下さい。
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              ご指摘・ご質問などは、
              <a href="https://twitter.com/y_kawase" target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
              もしくは
              <a href="https://blog.f-arts.work/" target="_blank" rel="noopener noreferrer">
                当方ブログお問い合わせ
              </a>
              よりご連絡下さい。
            </Typography> */}
          </Paper>
          
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              IMS JAPAN CONFERENCE DEMO Badge URL
            </Typography>
            <Typography component="p" className={classes.paragraph}>
            https://demo.mananda.net/openbadges/Assertion/imsjapandemoAssertion.json
            </Typography>
            <Typography component="p" className={classes.paragraph}>
            email: t-hata@digital-knowledge.co.jp
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoImage.svg" target="_blank" rel="noopener noreferrer">
              SVG Badge : https://demo.mananda.net/openbadges/BadgeClass/imsjapandemoImage.svg
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://demo.mananda.net/openbadges/Assertion/imsjapandemoAssertion.svg" target="_blank" rel="noopener noreferrer">
              SVG Assertion Badge : https://demo.mananda.net/openbadges/Assertion/imsjapandemoAssertion.svg
              </a>
            </Typography>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              IMS JAPAN CONFERENCE 2019 講演資料
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://demo.mananda.net/resources/IMS JAPAN CONFERENCE 2019 OPEN BADGES 20190924講演資料ワークショップ 1.pdf" target="_blank" rel="noopener noreferrer">
              ワークショップ 1　『１から学ぶ Digital Badges』第2部　デジタルバッジを体験する
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://demo.mananda.net/resources/IMS JAPAN CONFERENCE 2019 OPEN BADGES 20190924講演資料5.pdf" target="_blank" rel="noopener noreferrer">
              5. IMS Global 諸技術標準の国内での取組み状況および Case Study　デジタル・ナレッジ（Digital Badges）
              </a>
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              <a href="https://demo.mananda.net/resources/IMS JAPAN CONFERENCE 2019 OPEN BADGES 20190925講演資料10-11.pdf" target="_blank" rel="noopener noreferrer">
              IMS Japan Society Tech Day　セッション: IMS認定製品/サービス実装報告　Digital Badges
              </a>
            </Typography>            
          </Paper>        
        </div>
      </div>
    );
  }
}

// Material-ui関連
Info.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(Info);