import React, { Component } from 'react';

// コンテナ読み込み
import ResponsiveDrawer from './containers/ResponsiveDrawer';
import RouteRelatedBottomNavigation from './containers/RouteRelatedBottomNavigation';
import Notification from './containers/Notification';
import Home from './containers/Home';
import Info from './containers/Info';
import InfoIssuer from './containers/InfoIssuer';
import InfoBadgeClass from './containers/InfoBadgeClass';
import InfoAssertion from './containers/InfoAssertion';
import InfoAssertionAll from './containers/InfoAssertionAll';

import Settings from './containers/Settings';

// コンポーネント読み込み
import WrapMainContent from './components/WrapMainContent'

// 共通スタイル読み込み
import './App.css';

// Route関連
import { Route, Switch } from 'react-router-dom';

import Amplify,{ API, I18n } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1', 
    identityPoolId: 'ap-northeast-1:ff67a896-f2f0-417b-936d-53a2ebf1aa35', 
    userPoolId: 'ap-northeast-1_DOQ6UfUZ7',
    userPoolWebClientId: '34c1asg74bpor1pf90dhs60fkm', 
  },
  API: {
    endpoints: [
        {
            name: "MyAPIGatewayAPI",
            endpoint: "https://3ujnwmki7d.execute-api.ap-northeast-1.amazonaws.com/prod/imsjapan"
        },
        {
          name: "obv2Issuer",
          endpoint: "https://demo.mananda.net/openbadges/Issuer/imsjapandemoIssuer.json"
      }
    ]
}
});

const dict = {
  'ja': {
    'Account recovery requires verified contact information': "アカウントを復旧するには連絡先の確認が必要です",
    'Back to Sign In': "サインイン画面へ戻る",
    'Change Password': "パスワード変更",
    'Change': "変更",
    'Code': "確認コード",
    'Confirm a Code': "コードを確認する",
    'Confirm Sign In': "確認",
    'Confirm Sign Up': "サインアップ",
    'Confirm': "確認",
    'Email': "メールアドレス",
    'Forgot Password': "パスワードをお忘れの場合",
    'Loading...': "ロード中...",
    'New Password': "新しいパスワード",
    'No MFA': "MFAなし",
    'Password': "パスワード",
    'Phone Number': "電話番号",
    'Pick a File': "ファイルを選択する",
    'Resend a Code': "確認コードを再送する",
    'Resend Code': "確認コードを再送する",
    'Select MFA Type': "MFAタイプの選択",
    'Select your preferred MFA Type': "MFAタイプを選択してください",
    'Sign In Account': "サインイン",
    'Sign In': "サインイン",
    'Sign Out': "サインアウト",
    'Sign Up Account': "サインアップ",
    'Sign Up': "サインアップ",
    'Skip': "スキップする",
    'Submit': "保存",
    'Username': "ユーザー名",
    'Verify Contact': "確認",
    'Verify': "確認する",
    'TOTP Setup': "時間ベースのワンタイム (TOTP) パスワードを関連付けする",  
    'Get secret key': "シークレットキーコードを取得する",
    'totp verification token': "シークレットキーコードを検証する",
    'Send Code': "送信する",
    'Invalid code received for user':"確認コードが違います",
    'Account recovery requires verified contact information':
    "アカウントの修復に確認済みコンタクト情報が必要です",
    'User does not exist': "ユーザは登録されていません",
    'User already exists': "ユーザは既に登録されています",
    'Incorrect username or password': "ユーザ名かパスワードが異なります",
    'Invalid password format': "パスワードの形式が違います",
    'Invalid phone number format': "電話番号の形式が違います +12345678900",
    'Enter your username': "ユーザ名入力",
    'Enter your password': "パスワード入力",
    'Forget you password?': "パスワードをお忘れの場合",
    'No account?': "サインアップ",
    'Sign in to your account': "サインイン"
   }
};

I18n.putVocabularies(dict);
I18n.setLanguage('ja');

const MyTheme = {
  googleSignInButton: { backgroundColor: "red", borderColor: "red" },
  button: { backgroundColor: "green", borderColor: "red" },
  signInButtonIcon: { display: "none" }
};

const backpack ='https://badgecheck.io/'



// 不明なRouteは全てNotFound
const NotFound = () => {
  return(
    <h2>ページが見つかりません</h2>
  )
}


class App extends Component {

  render() {
    return (
      <div className="App">
        <Notification/>
        <ResponsiveDrawer className="ResponsiveDrawer">
          <Switch>
            <Route exact path="/" component={WrapMainContent(Home)} />
            <Route exact path="/info" component={WrapMainContent(Info)}/>
            <Route exact path="/InfoIssuer" component={WrapMainContent(InfoIssuer)}/>
            <Route exact path="/InfoBadgeClass" component={WrapMainContent(InfoBadgeClass)}/>
            <Route exact path="/InfoAssertion" component={WrapMainContent(InfoAssertion)}/>
            <Route exact path="/InfoAssertionAll" component={WrapMainContent(InfoAssertionAll)}/>
            <Route exact path="/settings" component={WrapMainContent(Settings)}/>
            <Route component={WrapMainContent(NotFound)}/>
          </Switch>
        </ResponsiveDrawer>
        <RouteRelatedBottomNavigation/>
      </div>
    );
  }
}


// React-Router情報取得
// export default App;
// export default withAuthenticator(App, {
//   signUpConfig: {
//     hiddenDefaults: ["phone_number"],
//     signUpFields: [
//       { label: "Name", key: "name", required: true, type: "string" }
//     ]
//   }
// });

export default withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: true, 
  // Show only certain components
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
    signUpFields: [
      { label: "Name", key: "name", required: true, type: "string" }
    ]
  },
  // customize the UI/styling
  theme: {MyTheme}});

// export default withAuthenticator(App, true, [], null, MyTheme, {
  // signUpConfig: {
  //   hiddenDefaults: ["phone_number"],
  //   signUpFields: [
  //     { label: "Name", key: "name", required: true, type: "string" }
  //   ]
// }});
